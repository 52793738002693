import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { timeAgo } from '../../shared/constants/constant'
import { FaSpinner } from 'react-icons/fa'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useCompany } from '../../contexts/CompanyContext'
import apiClient from '../../lib'

function NotificationsTable({ data }) {
  const [selectedNotification, setSelectedNotification] = useState(null)
  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectedNotification(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleRowClick = (notification) => {
    setSelectedNotification(notification)
  }

  const notifications = data?.aNotifications || [];

  return (
    <section className="pb-7">
      <div className="flex flex-row w-full">
        <div className="basis-full w-full">
          <div className="p-6 bg-primary-600 rounded-2xl w-full h-full">
            <h2 className="text-sm font-semibold pb-2">National Case Laws</h2>
            <div className="flex flex-col">
              <div className="text-justify text-xs text-secondary-200 font-normal border-b-2 border-secondary-200 py-2 flex">
                <div className="w-2/6">Title</div>
                <div className="w-1/4 flex items-center">Date</div>
                <div className="w-1/4 flex items-center">Link</div>
              </div>
              <div className="text-xs font-normal flex flex-col py-2">
                {notifications?.map((notification) => (
                  <div 
                    key={notification._id} 
                    className="py-2 flex hover:bg-primary-700 cursor-pointer"
                    onClick={() => handleRowClick(notification)}
                  >
                    <div className="w-2/6">{notification.sTitle}</div>
                    <div className="w-1/4">{timeAgo(new Date(notification.dPubDate))}</div>
                    <div className="w-1/4">
                      <a 
                        href={notification.sEurLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-600 hover:underline"
                        onClick={(e) => e.stopPropagation()}
                      >
                        View Document
                      </a>
                    </div>  
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedNotification && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div 
            ref={modalRef}
            className="bg-white rounded-lg max-w-2xl w-full p-6"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{selectedNotification.sTitle}</h3>
              <button 
                onClick={() => setSelectedNotification(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <div className="prose max-w-none">
              <p className="text-gray-600">{selectedNotification.description}</p>
            </div>
            <div className="mt-4 text-sm text-gray-500">
              Published: {timeAgo(new Date(selectedNotification.dPubDate))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default NotificationsTable

NotificationsTable.propTypes = {
  data: PropTypes.any
} 