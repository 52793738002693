import React, { useState, useMemo } from "react";
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

function DetailsTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState('');
  const itemsPerPage = 10;

  const filteredDocuments = useMemo(() => {
    return (data || [])
      .filter(doc => !selectedCountry || doc.country === selectedCountry)
      .sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));
  }, [data, selectedCountry]);

  const totalDocuments = filteredDocuments.length;
  const totalPages = Math.ceil(totalDocuments / itemsPerPage);
  
  const currentDocuments = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredDocuments.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredDocuments, currentPage]);

  const uniqueCountries = useMemo(() => {
    return [...new Set((data || []).map(doc => doc.country))].filter(Boolean);
  }, [data]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <section className="pb-7">
      <div className="bg-gray-100 rounded-2xl p-6 shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Document Details</h2>
          <div className="text-gray-600">
            Total Documents: {totalDocuments}
          </div>
        </div>

        <div className="mb-4">
          <select
            className="px-4 py-2 border rounded-lg"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">All Countries</option>
            {uniqueCountries.map(country => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
        </div>
        
        <div className="space-y-4">
          {currentDocuments.map((doc, index) => (
            <div key={index} className="bg-white rounded-xl p-4 shadow">
              <div className="flex justify-between items-start mb-2">
                <h3 className="text-xl font-semibold text-gray-800">{doc.title}</h3>
                <div className="text-sm text-gray-500">
                  {formatDate(doc.pub_date)} | {doc.country}
                </div>
              </div>
              <Markdown>{doc.value}</Markdown>
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center items-center mt-6 space-x-2">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 border rounded-lg disabled:opacity-50"
            >
              Previous
            </button>
            <span className="text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-4 py-2 border rounded-lg disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default DetailsTable;

DetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      pub_date: PropTypes.string.isRequired,
    })
  )
}
